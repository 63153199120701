import { UserActions } from "./userActions";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { DesktopToken, localStore } from "../../services/desktopStoreService";
import { Platform } from "react-native";
import User, { UserMetaData } from "../../types/User";
import { getUserMetaData } from "../../services/userService";

export interface UserState {
    token: string,
    userId: string,
    username: string,
    mode: 'local' | 'cloud',
    user_data?: User,
    meta_data: UserMetaData
}

const INITIAL_STATE = {
    token: "",
    userId: "",
    username: "",
    mode: 'local',
    meta_data: {
        last_active: 0,
        last_daily_import_date: "",
        user_id: ""
    }
} as UserState

const writeToken = async (token: string) => {
    try {
        // @ts-ignore
        if (window.__TAURI__) {
            // console.log("write token", token)
            await localStore.set('session', { token } as DesktopToken);
        } else {
            if (['android', 'ios'].includes(Platform.OS)) {
                await AsyncStorage.setItem('@token', token)
            }
        }

    } catch (e) {
        // saving error
        console.log("writing error")
    }
}

export function userReducer(state = INITIAL_STATE, action: { type: string, payload: unknown }): UserState {
    switch (action.type) {
        case UserActions.START_USER_LOGIN_ACTION:
            return { ...state, username: action.payload as string }
        case UserActions.SUCCESSFUL_LOGIN_ACTION:
            writeToken(action.payload as string)
            return { ...state, token: action.payload as string, mode: action.payload === 'LOCAL_LOGIN' ? 'local' : 'cloud' }
        case UserActions.SUCCESSFUL_LOGOUT_ACTION:
            return { ...INITIAL_STATE }
        case UserActions.SUCCESSFUL_GET_USER_ACTION:
            return { ...state, user_data: action.payload as User, username: (action.payload as User).username }
        case UserActions.SET_USER_META_DATA:
            return { ...state, meta_data: action.payload as UserMetaData }
        default:
            return state
    }
};
