import { PhysicalSize, appWindow } from '@tauri-apps/api/window';
import { getOS } from '../utils/env';

export function adjustWeb() {
    const os = getOS()
    const titlebarEl = document.getElementById("titlebar")
    if (!titlebarEl) {
        return
    }
    // @ts-ignore
    if (!!window && !!window.__TAURI__ && os.platform == 'darwin') {
        // titlebarEl.style.backgroundColor = "#252B35"
        titlebarEl.style.display = 'block'
    }
    // @ts-ignore
    if (!!window.__TAURI__) {
        const [width, height] = get_default_dems()
        appWindow.setMinSize(new PhysicalSize(width, height))
            .then(() => {
                // console.log("success")
            }).catch(() => {
                // console.log("error")
            })
    }
}

export function setTopBarColor() {
    const os = getOS()
    const titlebarEl = document.getElementById("titlebar")
    if (!titlebarEl) {
        return
    }

    // @ts-ignore
    if (!!window && !!window.__TAURI__ && os.platform == 'darwin') {
        titlebarEl.style.backgroundColor = "#252B35"
    }
}

export function get_default_dems(): [number, number] {
    const os = getOS()
    switch (os.platform) {
        case 'darwin':
            return [640, 800]
        default:
            return [500, 450]
    }
}