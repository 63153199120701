
import Task, { MappedTasks } from "../types/Task"

export const normalizeTasks = (tasks: Task[]) => {
    const tasksMapped: MappedTasks = {}
    for (let task of tasks) {
        tasksMapped[task.id] = task
    }
    return tasksMapped;
}

export const activeTaskFilter = (task: Task) => task.active

export const normalizeActiveTasks = (tasks: Task[]) => normalizeTasks(tasks.filter(activeTaskFilter))

export const sortFunc = (taskA: Task, taskB: Task) => ((taskA.updated_at > taskB.updated_at) ? -1 : 1)

export function seperateTasks(tasks: MappedTasks, disallowSort: boolean = false) {
    const uncheckedTasksList: Task[] = []
    const checkedTasksList: Task[] = []

    for (const taskId of Object.keys(tasks)) {
        if (tasks[taskId].checked) {
            checkedTasksList.push(tasks[taskId])
        } else {
            uncheckedTasksList.push(tasks[taskId])
        }
    }

    if (!disallowSort) {
        return [uncheckedTasksList.sort(sortFunc), checkedTasksList.sort(sortFunc)]
    }

    return [uncheckedTasksList, checkedTasksList]
}

export function seperateAndSortTasks(tasks: MappedTasks) {
    const _tasks = []
    const [uncheckedTasksList, checkedTasksList] = seperateTasks(tasks)
    for (let task of uncheckedTasksList) {
        _tasks.push(task)
    }
    for (let task of checkedTasksList) {
        _tasks.push(task)
    }
    return normalizeTasks(_tasks)
}

export function seperateAndKeepOrderTasks(oldTasks: MappedTasks, tasks: MappedTasks): MappedTasks{
    const oldTasksKeys = Object.keys(oldTasks)
    const tasksKeys = Object.keys(tasks)
    const _tasks = { ...oldTasks } as MappedTasks
    const removeTaskIds = []

    for (let oldTaskId of oldTasksKeys) {
        if (tasks[oldTaskId]) {
            _tasks[oldTaskId] = tasks[oldTaskId]
        } else {
            removeTaskIds.push(oldTaskId)
        }
    }

    for (let taskId of removeTaskIds) {
        delete _tasks[taskId]
    }
    
    return _tasks
}



