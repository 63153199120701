import React, { useState } from "react"
import { View, Text, Platform, StyleSheet, Pressable, Switch } from "react-native"
import AsyncStorage from '@react-native-async-storage/async-storage';
import cColors from "../styles/componentColors";
import { logoutUser } from "../services/userService";
import { store } from "../redux";
import { wipeLocalTasks } from "../services/tasksService";
import { UserState } from "../redux/user/userReducer";
import { toggleEnableNotification } from "../redux/visual/visualActions";
import { storeEnabledNotificationState } from '../utils/notifications'
import CustomContainer from "../components/CustomContainer";
import { delay } from "../utils/delay";

const logoutProcess = async () => {
    try {
        if (Platform.OS === 'ios' || Platform.OS === 'android') {
            await AsyncStorage.removeItem('@token')
        }
        await logoutUser()
        await delay(10)
    } catch (e) {
        // saving error

    }
}

const styles = StyleSheet.create({
    content: {
        justifyContent: 'center',
        flex: 1
    },
    icon: {
        width: 60,
        height: 60,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: cColors.TOP_BAR_ICON_COLOR,
        borderRadius: 90
    },
    iconLetter: {
        fontSize: 20,
        textAlign: 'center'
    },
    settingsItemList: {
        paddingHorizontal: 11,
    },
    settingsItem: {
        paddingHorizontal: 6,
        paddingVertical: 10,
        flexDirection: 'row',
        alignItems: 'center',
        borderBottomColor: cColors.SETTING_ITEM_BORDER_COLOR,
        borderBottomWidth: .3
    },
    settingsItemText: {
        color: cColors.SETTING_ITEM_TEXT_COLOR,
        paddingHorizontal: 8,
        fontSize: 16,
    }
});


export default function SettingsScreen(props: { navigation: any }) {
    const state = store.getState()
    const user = state.user as UserState
    const visual = state.visual
    const mode = user.mode
    const [isEnabled, setIsEnabled] = useState<boolean>(visual.enableNotification);
    const toggleSwitch = () => {
        storeEnabledNotificationState(!isEnabled).then(() => { })
        store.dispatch(toggleEnableNotification(!isEnabled))
        setIsEnabled(previousState => !previousState)
    }


    return <CustomContainer>
        <View
            style={{ backgroundColor: cColors.PAGE_BG_COLOR, flex: 1, paddingTop: 10 }}>

            <View style={{ paddingVertical: 10, paddingHorizontal: 5, marginBottom: 4 }} >
                <View style={{ paddingVertical: 10, paddingHorizontal: 10, marginBottom: 4, flexDirection: 'row' }} >
                    <View style={{ paddingVertical: 8, paddingHorizontal: 10 }} >
                        <View style={styles.icon}>{user.username.length > 0 && <Text style={styles.iconLetter}>{`${user.username[0]}`}</Text>}</View>
                    </View>
                    <View style={{}} >
                        <View style={{ paddingVertical: 0, paddingHorizontal: 20, marginBottom: 4, flexDirection: 'row' }} >
                            <Text style={{ color: 'white', fontSize: 16 }}>{user.user_data?.first_name + " "}</Text>
                            <Text style={{ color: 'white', fontSize: 16 }}>{user.user_data?.last_name}</Text>
                        </View>
                        <Text style={{ color: 'white', fontSize: 16, paddingHorizontal: 20, marginBottom: 4, }}>{user.user_data?.username}</Text>
                        <Text style={{ color: 'white', fontSize: 16, paddingHorizontal: 20, marginBottom: 4, }}>{user.user_data?.email}</Text>
                    </View>
                </View>
                <View style={{ paddingVertical: 10, paddingHorizontal: 20, marginBottom: 4 }} >
                </View>
            </View>
            {Platform.OS === 'android' || Platform.OS === 'ios'
                && <View style={styles.settingsItemList}>
                    <View style={styles.settingsItem}>
                        <Switch
                            trackColor={{ false: 'gray', true: 'white' }}
                            thumbColor={isEnabled ? cColors.SWITCH_THUMB_COLOR_ENABLED : cColors.SWITCH_THUMB_COLOR_DISABLED}
                            ios_backgroundColor="#3e3e3e"
                            onValueChange={toggleSwitch}
                            value={isEnabled}
                            style={
                                {}
                            }
                        />
                        <Text style={styles.settingsItemText}>
                            Enable Notification
                        </Text>
                    </View>
                </View>

            }

            <Pressable
                onPress={() => {
                    storeEnabledNotificationState(false).then(() => {
                        logoutProcess()
                            .then(() => {
                                props.navigation.navigate("SignInScreen")
                            })
                    })
                }}
            >
                <View style={{ paddingVertical: 10, paddingHorizontal: 20, marginBottom: 4 }} >
                    <Text style={{ color: 'white', fontSize: 16 }}>Logout</Text>
                </View>
            </Pressable>
            {mode === 'local' &&
                <Pressable
                    onPress={() => {
                        wipeLocalTasks()
                    }}
                >
                    <View style={{ paddingVertical: 10, paddingHorizontal: 20 }} >
                        <Text style={{ color: 'white', fontSize: 16 }}>Wipe Local Tasks</Text>
                    </View>
                </Pressable>}

        </View>
    </CustomContainer>
}
