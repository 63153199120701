import { WebSQLDatabase } from "expo-sqlite/legacy";
import Logger from "./external/logger";
import env from '../utils/env';

//@ts-ignore
const logger = new Logger(env.LOGGER_TOKEN)

export const writeDBFile = async (dbUri: string) => {
  return
}

export const EMPTY_DB = {
  transaction: () => {
    return {
      executeSql: () => { },
    };
  },
};

export async function openDatabase() {
  return EMPTY_DB
}


export const simpleTransaction: (db: WebSQLDatabase, query: string, params: (string | number)[]) => Promise<any | undefined> = (db: WebSQLDatabase, query: string, params: (string | number)[]) => {
  return new Promise((resolve, reject) => db.transaction(
    (tx) => {
      tx.executeSql(
        query,
        params,
        (_, output) => {
          resolve(output.rows._array)

        }
      );
    },
    (e) => {
      reject(e)
    },
    () => {
      // console.log("success")
    }))
}
