import * as React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import Home from './screens/Home'
import InitScreen from './screens/InitScreen';
import AddTaskScreen from './screens/AddTaskScreen';
import AddMulipleTasksScreen from './screens/AddMulipleTasksScreen';
import EditTaskScreen from './screens/EditTaskScreen';
import SignInScreen from './screens/SignInScreen'
import SignUpScreen from './screens/SignUpScreen'
import SaveForLaterTasksListScreen from './screens/SaveForLaterTasksListScreen'
import CreateContextScreen from './screens/CreateContextScreen';
import EditContextScreen from './screens/EditContextScreen';
import ResetPasswordScreen from './screens/ResetPasswordScreen';
import SetNewPasswordScreen from './screens/SetNewPasswordScreen';
import { RootSiblingParent } from 'react-native-root-siblings';
import { StatusBar } from 'expo-status-bar';
import cColors from './styles/componentColors';
import 'intl';
import 'intl/locale-data/jsonp/en';
import { Provider } from 'react-redux'
import { store } from './redux';
import { loadAllTasks } from './services/tasksService';
import { Platform } from 'react-native';
import OnEnterScreen from './screens/OnEnterScreen';
import SettingsScreen from './screens/SettingScreen';
import 'react-native-get-random-values'
import CustomColorScreen from './screens/CustomColorScreen';
import * as Notifications from 'expo-notifications';
import { Subscription, } from 'expo-modules-core';
import { adjustWeb } from './utils/platform';
import RightClickMenu from './components/RightClickMenu';
import { setOsEnv } from './utils/env';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
function title(title: string) {
  return `${title} - minilist`
}
// @ts-ignore
if (Platform.OS !== 'web') {
  Notifications.setNotificationHandler({
    handleNotification: async () => ({
      shouldShowAlert: true,
      shouldPlaySound: false,
      shouldSetBadge: false,
    }),
  });
}

const Stack = createNativeStackNavigator();

export default function App() {
  const notificationListener = React.useRef<undefined | Subscription>();
  const responseListener = React.useRef<undefined | Subscription>();

  React.useEffect(() => {
    setOsEnv().then(() => {
      adjustWeb()
    })
    if (Platform.OS !== 'web') {
      notificationListener.current = Notifications.addNotificationReceivedListener(() => {
        // this runs when notification is registered
        // setNotification(notification);
      });

      responseListener.current = Notifications.addNotificationResponseReceivedListener(response => {
        // this runs when user interacts
        // setNotification(undefined)
      });
    }


    if (['windows', 'macos', 'web'].includes(Platform.OS)) {
      document.body.style.backgroundColor = cColors.PAGE_BG_COLOR
    }
    const reload = (
      event: any
    ) => {
      if ((event.code === 'KeyR' && event.metaKey) || (event.ctrlKey && event.code === 'KeyR')) {
        loadAllTasks()
      }
    }
    //@ts-ignore
    if (window.__TAURI__) {
      document.body.addEventListener("keypress", reload)
    }

    return () => {
      //@ts-ignore
      if (window.__TAURI__) {
        document.body.removeEventListener('keypress', reload)
      } else if (notificationListener.current || responseListener.current) {
        Notifications.removeNotificationSubscription(notificationListener.current as Subscription);
        Notifications.removeNotificationSubscription(responseListener.current as Subscription);
      }
    }
  }, [])

  return (<Provider store={store}>
    <GestureHandlerRootView style={{ flex: 1 }}>
      <StatusBar style="light" />
      <RightClickMenu />
      <NavigationContainer>
        <RootSiblingParent>
          <Stack.Navigator>
            <Stack.Screen name="InitScreen" component={InitScreen} options={{ headerShown: false, title: "Welcome to minilist!" }} />
            <Stack.Screen name="SignInScreen" component={SignInScreen} options={{ headerShown: false, animation: 'none', gestureEnabled: false, title: title("Sign In") }} />
            <Stack.Screen name="SignUpScreen" component={SignUpScreen} options={{ headerShown: false, animation: 'none', gestureEnabled: false, title: title("Sign Up") }} />
            <Stack.Screen name="ResetPasswordScreen" component={ResetPasswordScreen} options={{ headerShown: false, animation: 'none', gestureEnabled: false, title: title('Reset password') }} />
            <Stack.Screen name="SetNewPasswordScreen" component={SetNewPasswordScreen} options={{ headerShown: false, animation: 'none', gestureEnabled: false, title: title('Set new password') }} />
            <Stack.Screen name="SettingsScreen" component={SettingsScreen} options={{
              headerTitle: '',
              headerStyle: { backgroundColor: cColors.NAV_HEADER_BG_COLOR },
              headerTintColor: cColors.NAV_HEADER_TEXT_COLOR,
              headerShadowVisible: false,
              title: title('Settings'),
              headerBackTitleVisible: false
            }} />
            <Stack.Screen name="Home" component={Home} options={{ headerShown: false, gestureEnabled: false, animation: 'none', title: title('Home') }} />
            <Stack.Screen name="AddTask" component={AddTaskScreen} options={{ presentation: 'modal', headerShown: false, title: "Add Task", headerStyle: { 'backgroundColor': cColors.NAV_HEADER_BG_COLOR, }, headerTintColor: 'white' }} />
            <Stack.Screen name="CreateContext" component={CreateContextScreen} options={{ presentation: 'modal', headerShown: false, title: "Add Task", headerStyle: { 'backgroundColor': cColors.NAV_HEADER_BG_COLOR, }, headerTintColor: 'white' }} />
            <Stack.Screen name="EditContext" component={EditContextScreen} options={{ presentation: 'modal', headerShown: false, title: "Add Task", headerStyle: { 'backgroundColor': cColors.NAV_HEADER_BG_COLOR, }, headerTintColor: 'white' }} />
            <Stack.Screen name="CustomColorScreen" component={CustomColorScreen} options={{ presentation: 'fullScreenModal', headerShown: false, title: "Add Task", headerStyle: { 'backgroundColor': cColors.NAV_HEADER_BG_COLOR, }, headerTintColor: 'white' }} />
            <Stack.Screen name="AddMultipleTasks" component={AddMulipleTasksScreen} options={{ presentation: 'modal', headerShown: false, title: "Add Mulitple Tasks", headerStyle: { 'backgroundColor': cColors.NAV_HEADER_BG_COLOR, }, headerTintColor: 'white' }} />
            <Stack.Screen name="EditTask" component={EditTaskScreen} options={{ presentation: 'modal', headerShown: false, title: "Add Task", headerStyle: { 'backgroundColor': cColors.NAV_HEADER_BG_COLOR, }, headerTintColor: 'white' }} />
            <Stack.Screen name="SaveForLaterTasksList" component={SaveForLaterTasksListScreen} options={{ presentation: 'modal', headerShown: false, title: "Save For Later Tasks", headerStyle: { 'backgroundColor': cColors.NAV_HEADER_BG_COLOR, }, headerTintColor: 'white' }} />
            <Stack.Screen name="OnEnterScreen" component={OnEnterScreen} options={{ presentation: 'modal', headerShown: false, title: "Save For Later Tasks", headerStyle: { 'backgroundColor': cColors.NAV_HEADER_BG_COLOR, }, headerTintColor: 'white' }} />
          </Stack.Navigator>
        </RootSiblingParent>
      </NavigationContainer>
    </GestureHandlerRootView>
  </Provider>
  );
}
