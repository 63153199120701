import { Platform, View } from "react-native"
import Task, { TaskType } from '../types/Task';
import TaskInformationView from "../components/TaskInformationView";
import { loadAllTasks, saveTask } from "../services/tasksService";
import ModalContainer from "../components/ModalContainer";
import CustomContainer from "../components/CustomContainer";
import { useRef } from "react";

export default (props: { navigation: any, route: any }) => {

    const save = async (task: Task) => saveTask(task)

    const infoViewRef = useRef<any>();
    const { params } = props.route
    const withContext = params?.withContext;
    const withType = params?.withType

    return <ModalContainer
        navigation={props.navigation}
        enableRightButton={true}
        rightButtonText={"Create"}
        rightButtonWidth={Platform.OS === 'android' ? 73 : 72}
        rightButtonOnClick={() => {
            if (infoViewRef.current) {
                infoViewRef.current.saveTask()
            }
        }}
    >
        <CustomContainer>
            <TaskInformationView
                ref={infoViewRef}
                navigation={props.navigation}
                mode="create"
                saveTask={save}
                afterButtonPress={(task) => {
                    props.navigation.goBack()
                    loadAllTasks(false, withType === 'SAVE_FOR_LATER')
                }}
                withContext={withContext}
                withType={withType as TaskType}
            />
        </CustomContainer>

    </ModalContainer>
}