import AsyncStorage from "@react-native-async-storage/async-storage"
import { Platform } from "react-native"
import { HOST } from "../api"
import { store } from "../redux"
import { setMetaDataAction, successfulGetUser, successfulLoginAction, successfulLogoutAction } from "../redux/user/userActions"
import { UserMetaData } from "../types/User"
import { getNow, parseCurrentDateTimeIso } from "../utils/date"
import { toSnakeCaseKeys } from "../utils/objects"
import Logger from "./external/logger"
import env from "../utils/env"
import { fetchConfig } from "../utils/headers"
const logger = new Logger((env.LOGGER_TOKEN) || "")

export const getUserMetaData = async (altToken?: string) => {
    const user: { mode: string } = store.getState().user
    let userInfo: UserMetaData;
    if (user.mode === 'local') {
        userInfo = await localGetUserMetaData()
    } else {
        userInfo = await fetch(`${HOST}/user/metadata`, {
            method: 'GET',
            ...fetchConfig()
        }).then(res => res.json())
        store.dispatch(setMetaDataAction(userInfo as UserMetaData))
    }
    return userInfo as UserMetaData
}

const localGetUserMetaData = async () => {
    let last_daily_import_date = ""
    try {
        // @ts-ignore
        if (window.__TAURI__) {
            last_daily_import_date = await localStorage.get('user_meta_data').lastDailyImportDate || "";
        } else {
            if (['android', 'ios'].includes(Platform.OS)) {
                last_daily_import_date = await AsyncStorage.getItem('user_meta_data.last_daily_import_date') || ""
            }
        }
    } catch (e) {
        // saving error
        console.log("loading error")
    }

    return { user_id: '', last_active: 0, last_daily_import_date } as UserMetaData
}

export const getUser = async (altToken?: string) => {
    try {
        const token = altToken ? altToken : (store.getState().user as { token: string }).token
        logger.log("GETTING_USER", `token ${token}`)

        const user = await fetch(`${HOST}/user`, {
            method: 'GET',
            ...fetchConfig(altToken)

        }).then(res => {
            return res.json()
        })
        store.dispatch(successfulGetUser(user))
        return {
            response: "Success",
            user
        }
    } catch (e) {
        return {
            response: "Unauthorized",
        }
    }
}

export const logoutUser = async (altToken?: string) => {
    const userState: { mode: string, token: string } = store.getState().user

    if (userState.mode === 'local') {
        store.dispatch(successfulLogoutAction())
        return
    }

    const user = await fetch(`${HOST}/auth/logout`, {
        method: 'GET',
        ...fetchConfig()

    }).then(res => res.json())

    store.dispatch(successfulLogoutAction())
    return user
}

const setDailyImportDateLocal = async (date: string) => {
    try {
        // @ts-ignore
        if (window.__TAURI__) {
            await localStorage.set('user_meta_data', { lastDailyImportDate: date })
        } else {
            if (['android', 'ios'].includes(Platform.OS)) {
                await AsyncStorage.setItem('user_meta_data.last_daily_import_date', date)
            }
        }
    } catch (e) {
        // saving error
        console.log("loading error")
    }

    return { user_id: '', last_active: 0, last_daily_import_date: date } as UserMetaData

}

export const setDailyImportDate = async () => {
    const { token, mode } = store.getState().user
    if (mode === 'local') {
        return await setDailyImportDateLocal(parseCurrentDateTimeIso())
    }
    const userInfo = await fetch(`${HOST}/actions/set-last-daily-import-date`, {
        method: 'POST',
        body: JSON.stringify({ last_daily_import_date: parseCurrentDateTimeIso() }),
        ...fetchConfig()
    }).then(res => res.json())
    return userInfo
}

export interface SignUpForm {
    firstName: string,
    lastName: string,
    username: string,
    email: string,
    password: string,
}

export enum Source {
    Ios = "Ios",
    Android = "Android",
    Web = "Web",
    MacOs = "MacOs",
    Linux = "Linuix",
    Windows = "Windows"
}

export interface AuthWithPayload<T> {
    source: Source,
    payload: T
}

export const signUpUser = async (signUpForm: SignUpForm) => {
    const convertedForm = toSnakeCaseKeys(signUpForm)
    const sourceType = await getSource()
    const payload: AuthWithPayload<SignUpForm> = { source: sourceType, payload: convertedForm };
    const signUpResponse = await fetch(`${HOST}/auth/signup`, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json",
        }
    }).then(res => res.json())
    return signUpResponse
}

export const getSource: () => Promise<Source> = async () => {
    //@ts-ignore
    if (window.__TAURI__) {
        // @ts-ignore
        let platform = await window.__TAURI__.os.platform()

        if (platform === 'darwin') {
            return Source.MacOs
        } else if (platform === "linux") {
            return Source.Linux
        } else if (platform === 'win32') {
            return Source.Windows
        }
    }

    if (Platform.OS === 'android') {
        return Source.Android
    } else if (Platform.OS === 'ios') {
        return Source.Ios
    }

    return Source.Web

}