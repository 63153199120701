import { UserActions } from "../user/userActions";
import { ContextWidthsActions } from "./contextWidthsActions"


export type MappedContextWidths = { [key: string]: number | string }
let contextWidthsById: MappedContextWidths = {}

export function contextWidthsReducer(state = contextWidthsById, action: { type: string, payload?: any }): MappedContextWidths {
    switch (action.type) {
        case ContextWidthsActions.SET_CONTEXT_WIDTHS:
            return { ...action.payload }
        case ContextWidthsActions.SET_CONTEXT_WIDTH:
            const newState = { ...state };
            newState[action.payload.contextId] = action.payload.width
            return newState
        case UserActions.SUCCESSFUL_LOGOUT_ACTION:
            return {}
        default:
            return state
    }
}
