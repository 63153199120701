import { Platform } from "react-native"
import { EnvCostants } from "../types/Constants"
import Constants from "expo-constants"
import { Source } from "../services/userService"
export default Constants.expoConfig?.extra as EnvCostants ?? {
  LOGGER_TOKEN: "",
  GOOGLE_EXPO_WEB_CLIENT_ID: "",
  GOOGLE_ANDROID_CLIENT_ID: "",
  GOOGLE_IOS_CLIENT_ID: "",
  APPLE_WEB_CLIENT_ID: "",
  APPLE_WEB_REDIRECT_URI: "",
  RAILWAY_PUBLIC_DOMAIN: ""
} as EnvCostants


export const os = {
  platform: "",
  type: ""
}

export const getOS = () => window['OsEnv']

var startTime, endTime;

function start() {
  startTime = new Date();
};

function end() {
  endTime = new Date();
  var timeDiff = endTime - startTime; //in ms
  // strip the ms
  timeDiff /= 1000;

  // get seconds 
  var seconds = Math.round(timeDiff);
  console.log(seconds + " seconds");
}

export async function setOsEnv() {
  window["OsEnv"] = { ...os }
  if (!!window && !!window.__TAURI__) {
    start()
    let type = await window.__TAURI__.os.type()
    window["OsEnv"].type = type
    let platform = await window.__TAURI__.os.platform()
    window["OsEnv"].platform = platform
    end()
  }
}