import { useEffect } from "react"
import { View, Text, Platform, Dimensions, Linking } from "react-native"
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getUser } from "../services/userService";
import { store } from "../redux"
import { startUserLoginAction, successfulLoginAction } from "../redux/user/userActions";
import { localStore } from '../services/desktopStoreService';
import { useAssets } from "expo-asset";
import { writeDBFile } from "../services/localDBService";
import { delay } from "../utils/delay";

const { height } = Dimensions.get('window');

export default function InitScreen(props: { navigation: any }) {

    const [assets, assetsError] = useAssets([require('../assets/db/db.db')]);

    useEffect(() => {
        // console.log("useEffect()")
        if (assets && assets.length > 0) {
            writeDBFile(assets[0].localUri as string)
        }
        // console.log("before tauri")


        // @ts-ignore
        if (window.__TAURI__) {
            // console.log("after tauri")

            localStore.get('session').then((session: { token?: string | null } | any) => {
                // console.log("storage token", session)
                if (!!session.token) {
                    if (session.token === 'LOCAL_LOGIN') {
                        props.navigation.navigate('Home')

                    } else {
                        getUser(session.token).then((resp) => {
                            if (resp.response === "Success") {
                                store.dispatch(startUserLoginAction(resp.user.username))
                                store.dispatch(successfulLoginAction(session.token))
                                delay(40).then(() => {
                                    props.navigation.navigate('Home')
                                })
                            } else {
                                props.navigation.navigate('SignInScreen')
                            }
                        }).catch(e => {
                            props.navigation.navigate('SignInScreen')
                        })
                    }
                } else {
                    props.navigation.navigate('SignInScreen')
                }

            }).catch(e => {
                // console.log(e)
                // console.log(props.navigation.navigate)
                props.navigation.navigate('SignInScreen')
            })
        } else {

            if (['android', 'ios'].includes(Platform.OS)) {
                try {
                    AsyncStorage.getItem('@token').then((value) => {
                        if (value !== null) {
                            if (value === 'LOCAL_LOGIN') {
                                props.navigation.navigate('Home')
                            } else {
                                getUser(value).then((resp) => {
                                    if (resp.response === "Success") {
                                        store.dispatch(startUserLoginAction(resp.user.username))
                                        store.dispatch(successfulLoginAction(value))
                                        delay(40).then(() => {
                                            props.navigation.navigate('Home')
                                        })
                                    } else {
                                        props.navigation.navigate('SignInScreen')
                                    }
                                }).catch(e => {
                                    props.navigation.navigate('SignInScreen')
                                })
                            }
                        } else {
                            props.navigation.navigate('SignInScreen')
                        }
                    })
                } catch (e) {
                    // error reading value
                    // console.log('nav')
                    props.navigation.navigate('SignInScreen')
                }
            } else {
                getUser().then((resp) => {
                    if (resp.response === "Success") {
                        store.dispatch(startUserLoginAction(resp.user.username))
                        store.dispatch(successfulLoginAction(""))
                        delay(40).then(() => {
                            props.navigation.navigate('Home', { fromPage: "init" })
                        })
                    } else {
                        Linking.getInitialURL()
                            .then(path => {
                                if (path?.split('/')[3] === 'reset-password') {
                                    props.navigation.navigate('ResetPasswordScreen')
                                }
                                else if (path?.split('/')[3] === 'set-new-password') {
                                    props.navigation.navigate('SetNewPasswordScreen')
                                } else {
                                    props.navigation.navigate('SignInScreen')
                                }
                            })
                            .catch(e => {
                                props.navigation.navigate('SignInScreen')
                            })
                    }
                }).catch(e => {
                    props.navigation.navigate('SignInScreen')
                })

            }
        }
    }, [assets])
    return <View
        style={{
            backgroundColor: 'black',
            height
        }}>
        <Text>loading</Text>
    </View>
}
