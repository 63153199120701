import React from "react"
import { StyleSheet, View, Text, Platform, Dimensions } from "react-native"
import { SafeAreaView } from 'react-native-safe-area-context';
import ModalContainer from "../components/ModalContainer";
import { useEffect, useRef, useState } from "react";
import BottomButton from "../components/BottomButton";
import { RootSiblingParent } from 'react-native-root-siblings';
import { TriangleColorPicker, toHsv, fromHsv } from 'react-native-color-picker'
import { HsvColor } from "react-native-color-picker/dist/typeHelpers";
import { HexColorPicker } from "react-colorful";
import { ContextBubble } from "../components/ContextBubblesTopList";
import cColors from "../styles/componentColors";
import CustomContainer from '../components/CustomContainer';

const { height } = Dimensions.get('screen')
const styles = StyleSheet.create({
    header: {
        paddingTop: 0,
        paddingBottom: 12,
        fontSize: 22,
        color: cColors.HEADER_TEXT_COLOR
    },
    container: {
        backgroundColor: cColors.PAGE_BG_COLOR,
        flex: 1,
        paddingHorizontal: 14,
        display: 'flex',
    },
    text: {
        color: 'white'
    },
    label: {
        fontSize: 20,
        marginBottom: -36,
        color: cColors.LABEL_TEXT_COLOR
    },
    headerContainer: {
        // paddingVertical: 6
    }
})

export default (props: { navigation: any, route: any }) => {
    const once = useRef(false);
    const { customColor: color, setCustomColorCb } = props.route.params
    const [contextName, setContextName] = useState<string>('');
    const [patten, setPattern] = useState<string>('');
    const [contextColorName, setContextColorName] = useState<string>('');
    const [customColor, setCustomColor] = useState<HsvColor>(toHsv(color));

    const onButtonPress = () => { }
    const setColorOnPress = () => {
        setCustomColorCb(fromHsv(customColor))
        props.navigation.goBack()
    }

    useEffect(() => {
        const shortcutHandler = (event: any) => {
            if ((event.code === 'Enter' && event.metaKey) || (event.ctrlKey && event.code === 'Enter')) {
                onButtonPress()
            }
        }

        // @ts-ignore
        if (window.__TAURI__) {
            document.body.addEventListener("keypress", shortcutHandler)
        }
        return () => {
            // @ts-ignore
            if (window.__TAURI__) {
                document.body.removeEventListener('keypress', shortcutHandler)
            }
        }
    }, [onButtonPress, contextName])

    return <ModalContainer
        paddingTop={25}
        navigation={props.navigation}
    >
        <CustomContainer>
            <RootSiblingParent>
                <SafeAreaView style={styles.container} edges={['left']}>
                    <View style={styles.headerContainer}>
                        <Text style={styles.header}>{"Change custom color"}</Text>
                    </View>
                    <View style={{
                        paddingVertical: Platform.OS === 'web' ? 40 : 28,
                        paddingRight: 20,
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <ContextBubble
                            id={'example'}
                            context={{
                                id: 'example',
                                name: "example context",
                                color: fromHsv(customColor),
                                created_at: 0,
                                updated_at: 0,
                                created_by: 'j0no',
                                is_public: false
                            }}
                            contextIndex={'example'}
                            setContextIndex={() => { }}
                            setContextWidth={() => { }}
                            marginSide='left'
                        />
                    </View>

                    {Platform.OS === 'web' ?
                        <View style={{ width: '100%', flexDirection: 'column', display: 'flex', justifyContent: 'center', paddingVertical: 40, alignItems: 'center' }}>
                            <HexColorPicker
                                color={color}
                                onChange={(customColor) => {
                                    setCustomColor(toHsv(customColor))
                                }} />
                        </View>
                        :
                        <TriangleColorPicker
                            oldColor={color}
                            color={customColor}
                            onColorChange={(customColor) => {
                                setCustomColor(customColor)
                            }}
                            onColorSelected={color => { }}
                            onOldColorSelected={color => { }}
                            style={{ height: '60%' }}
                        />}
                </SafeAreaView>
            </RootSiblingParent>
            <BottomButton singleTap={setColorOnPress} text={"Set Color"} />
        </CustomContainer>
    </ModalContainer>
}

