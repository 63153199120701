import React from 'react'
import * as AppleAuthentication from 'expo-apple-authentication';
import { View, StyleSheet, Platform, Text, Image } from 'react-native';
import { HOST } from '../api';
import { toSnakeCaseKeys } from '../utils/objects';
import { warningToast } from '../utils/toast';
// @ts-ignore
import AppleSignin from 'react-apple-signin-auth';
import RoundedButton from './RoundedButton';
import env from '../utils/env';
import { AuthWithPayload, Source, getSource } from '../services/userService';
import { fetchLoginConfig } from '../utils/headers';

interface AppleLoginButtonProps {
    afterLoginSuccess: (token: string) => {}
}

export default (props: AppleLoginButtonProps) => {
    if (Platform.OS === 'web') {
        return <AppleSignin
            /** Auth options passed to AppleID.auth.init() */
            authOptions={{
                /** Client ID - eg: 'com.example.com' */
                clientId: env.APPLE_WEB_CLIENT_ID,
                /** Requested scopes, seperated by spaces - eg: 'email name' */
                scope: 'email name',
                /** Apple's redirectURI - must be one of the URIs you added to the serviceID - the undocumented trick in apple docs is that you should call auth from a page that is listed as a redirectURI, localhost fails */
                redirectURI: env.APPLE_WEB_REDIRECT_URI,
                /** State string that is returned with the apple response */
                state: 'state',
                /** Nonce */
                nonce: 'nonce',
                /** Uses popup auth instead of redirection */
                usePopup: true
            }} // REQUIRED
            /** General props */
            uiType="dark"
            /** className */
            className="apple-auth-btn"
            /** Removes default style tag */
            noDefaultStyle={false}
            /** Allows to change the button's children, eg: for changing the button text */
            buttonExtraChildren="Continue with Apple"
            /** Extra controlling props */
            /** Called upon signin success in case authOptions.usePopup = true -- which means auth is handled client side */
            onSuccess={(resp: any) => {
                const response = resp['user'] ? resp : { ...resp, user: null }
                const body = toSnakeCaseKeys(response)

                fetch(`${HOST}/auth/continue/apple-web`, {
                    method: 'POST',
                    body: JSON.stringify(body),
                    ...fetchLoginConfig()
                })
                    .then(resp => resp.json())
                    .then((appleContResp) => {
                        if (appleContResp.response === 'Success') {
                            props.afterLoginSuccess(appleContResp.token)
                        }
                        else {
                            warningToast("Failed to continue with Apple", 'bottom')
                        }
                    })
                    .catch(e => {
                        warningToast("Failed to continue with Apple", 'bottom')
                        console.log(e)
                    })

            }}
            /** Called upon signin error */
            onError={(error: any) => console.error(error)} // default = undefined
            /** Skips loading the apple script if true */
            skipScript={false} // default = undefined
            /** Apple image props */
            iconProp={{ style: { marginTop: '10px' } }} // default = undefined
            /** render function - called with all props - can be used to fully customize the UI by rendering your own component  */
            render={(props: any) => {
                return <RoundedButton {...props}
                    singleTap={props.onClick}
                    backgroundColor='#000000'
                >
                    <View style={{ flexDirection: 'row', alignItems: 'center', paddingLeft: 8, marginVertical: -8, backgroundColor: '#000000', borderRadius: 9999 }}>
                        <svg {...props.children.props.children[0].props}></svg>
                        <Text style={styles.continueButtonTextWhite}>{props.children.props.children[1]}</Text>
                    </View>
                </RoundedButton>
            }
            }
        />
    }


    return <AppleAuthentication.AppleAuthenticationButton
        buttonType={AppleAuthentication.AppleAuthenticationButtonType.CONTINUE}
        buttonStyle={AppleAuthentication.AppleAuthenticationButtonStyle.BLACK}
        cornerRadius={999}
        style={styles.button}
        onPress={async () => {
            try {
                const credential = await AppleAuthentication.signInAsync({
                    requestedScopes: [
                        AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
                        AppleAuthentication.AppleAuthenticationScope.EMAIL,
                    ],
                });
                // console.log(credential)
                // TODO: signed in
                const payload = toSnakeCaseKeys(credential)
                // console.log(payload)

                const sourceType = await getSource()
                // console.log(sourceType)
                const body: AuthWithPayload<any> = {
                    source: sourceType,
                    payload
                }
                // console.log()
                // console.log(JSON.stringify(body, null, 4))
                // console.log(body)
                try {
                    let appleContResp: any = await fetch(`${HOST}/auth/continue/apple`, {
                        method: 'POST',
                        body: JSON.stringify(body),
                        ...fetchLoginConfig()
                    })
                        .then(resp => resp.json())
                    // console.log(appleContResp)
                    if (appleContResp.response === 'Success') {
                        props.afterLoginSuccess(appleContResp.token)
                    }
                    else {
                        console.log("Failed to apple login")

                        warningToast("Failed to continue with Apple", 'bottom')
                    }
                } catch (e) {
                    console.log("Failed to apple login")
                    warningToast("Failed to continue with Apple Error", 'bottom')
                }

            } catch (e) {
                if (e.code === 'ERR_REQUEST_CANCELED') {
                    // handle that the user canceled the sign-in flow
                    warningToast("Canceled continue with Apple", 'bottom')
                } else {
                    // handle other errors
                    warningToast("Failed to continue with Apple", 'bottom')
                }
            }
        }}
    />
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    button: {
        width: 220,
        height: 44,
        alignSelf: 'center'
    },
    continueButtonText: {
        // backgroundColor: 'green',
        fontSize: Platform.OS === 'web' ? 14 : 16,
        paddingHorizontal: 4,
        // justifyContent: 'flex-end',
        flexDirection: 'row',
        // backgroundColor: 'red',
        textAlign: 'left',
        fontWeight: '500',
        display: 'flex'
    },
    continueButtonTextWhite: {
        // backgroundColor: 'green',
        fontSize: Platform.OS === 'web' ? 14 : 16,
        paddingHorizontal: 4,
        // justifyContent: 'flex-end',
        flexDirection: 'row',
        // backgroundColor: 'red',
        textAlign: 'left',
        fontWeight: '500',
        display: 'flex',
        color: 'white'
    },
});