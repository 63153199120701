import React from 'react'
import { StyleSheet, View, Text, Platform, Dimensions, FlatList, RefreshControl, Pressable, Alert, Button } from "react-native"
import cColors from "../styles/componentColors"
import { useState } from "react";
import BottomButton from "./BottomButton";
import { connect } from "react-redux";
import { MappedContexts, Context, ContextOrder } from "../types/Context";
import Svg, { Path } from "react-native-svg";
import { deleteContext, updateContextOrder } from "../services/contextService";
import BlankItem from "./BlankItem";
import DraggableFlatList from 'react-native-draggable-flatlist'
import { TouchableOpacity } from "react-native-gesture-handler";
import * as Haptics from 'expo-haptics';
import { Dialog, DialogContent, styles as dialogStyles } from './Dialog';

const { width, height } = Dimensions.get('screen')

const listHeight = height - 130 - 80

const styles = StyleSheet.create({
    title: {
        paddingTop: 0,
        paddingBottom: 12,
        fontSize: 24,
        color: cColors.MODAL_TITLE_TEXT_COLOR
    },
    container: {
        flex: 1,
        backgroundColor: cColors.PAGE_BG_COLOR,
        paddingHorizontal: 12
    },
    containerMobile: {
        width: width,
        paddingHorizontal: 12
    },
    page: {
        width: Platform.OS === 'android' || Platform.OS === 'ios' ? width : '100%',
        flex: 1,
        paddingHorizontal: 0,
        display: 'flex',
        backgroundColor: cColors.PAGE_BG_COLOR
    },
    headerContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingTop: 20,
        paddingBottom: 20,
        paddingHorizontal: 14
    },
    categoryContainer: {
        flexDirection: 'row',
        marginBottom: 10,
        height: 70,
    },
    category: {
        borderRadius: 9999,
        display: 'flex',
        paddingHorizontal: 12,
        paddingVertical: 12,
        marginRight: 6,
        borderWidth: 1,
        borderColor: cColors.DEFAULT_CONTEXT_BUBBLE_TEXT_COLOR,
        flexDirection: 'row'
    },
    categorySelected: {
        borderRadius: 9999,
        display: 'flex',
        paddingHorizontal: 14,
        paddingVertical: 12,
        marginRight: 8,
        backgroundColor: cColors.CONTEXT_BUBBLE_SELECTED_BG_COLOR,
    },
    categoryName: {
        color: 'white',
        fontSize: 18
    },
    categoryNameSelected: {
        color: cColors.CONTEXT_BUBBLE_SELECTED_TEXT_COLOR,
        fontSize: 18
    },
    contextScrollStyle: {
        display: 'flex',
    },
    deleteDialog: {

    }
})

// @ts-ignore
const RedTrashCanIcon = () => <Svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" height={30} width={30}
    stroke={cColors.CONTEXT_BUBBLE_DELETE_ICON_COLOR}
>
    <Path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
</Svg>

// @ts-ignore
const BarsIcon = () => <Svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" height={30} width={30}
    stroke={cColors.CONTEXT_BUBBLE_DRAG_TO_SORT_ICON_COLOR}
>
    <Path stroke-linecap="round" stroke-linejoin="round" d="M3.75 9h16.5m-16.5 6.75h16.5" />
</Svg>


const ContextView = (props: { navigation: any, contexts: MappedContexts, extraSpaceAtEnd?: boolean }) => {
    const [refreshing, setRefreshing] = useState(false);
    const [dragToSort, setDragToSort] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [toDeleteContext, setToDeleteContext] = useState<string | undefined>();


    const onRefresh = () => { }
    const addContextOnPress = () => {
        props.navigation.navigate("CreateContext")
    }
    const showAlert = (context: Context) => {
        if (Platform.OS === 'android' || Platform.OS === 'ios') {
            Alert.alert('How to handle tasks?', `${context.name}`, [
                {
                    text: 'Delete related tasks',
                    onPress: () => deleteContext(context.id, { deleteTasks: true }),
                },
                {
                    text: 'Cancel',
                    onPress: () => { },
                    style: 'cancel',
                },
                {
                    text: 'Keep tasks',
                    onPress: () => deleteContext(context.id, { deleteTasks: false }),
                },
            ]);
        } else {
            setOpenDeleteDialog(true)
            setToDeleteContext(context.id)
        }
    }
    let contextsArr = Object.keys(props.contexts).map(contextId => props.contexts[contextId])
    let hasPosition: Context[] = []
    let notHasPosition: Context[] = []

    for (let i = 0; i < contextsArr.length; i++) {
        if (typeof contextsArr[i].position !== 'undefined') {
            hasPosition.push(contextsArr[i])
        } else {
            notHasPosition.push(contextsArr[i])
        }
    }

    const contextsList = hasPosition.sort((a, b) => (a.position as number) - (b.position as number))

    notHasPosition.forEach(a => {
        contextsList.push(a)
    })

    if (true) {
        contextsList.push({
            id: "blank",
            name: "",
            created_by: "",
            created_at: 0,
            updated_at: 0,
            active: true,
            pattern: '',
            is_public: false
        })
    }

    return <View style={styles.page}>
        <View style={styles.headerContainer}>
            <Text style={styles.title}>{"Contexts"}</Text>
            <View style={{}}>
                <Button
                    color={Platform.OS === 'ios'
                        ? cColors.DRAG_TO_SORT_TEXT_COLOR_IOS
                        : cColors.DRAG_TO_SORT_TEXT_COLOR_NOT_IOS}
                    title={dragToSort ? "Done" : "Drag to Sort"}
                    onPress={() => {
                        setDragToSort(!dragToSort)
                    }}
                />
            </View>
        </View>
        <DraggableFlatList
            onDragEnd={(e) => {
                const order: ContextOrder = {}
                let index: number = 0;
                for (let i = 0; i < e.data.length; i++) {
                    const context = e.data[i];
                    if (context.id !== 'blank') {
                        order[context.id] = index
                        index++
                    }
                }
                updateContextOrder(order)
            }}
            nestedScrollEnabled
            refreshControl={
                <RefreshControl
                    refreshing={refreshing}
                    onRefresh={onRefresh}
                    tintColor={'white'}
                />}
            data={contextsList}
            keyExtractor={(item, index) => `${item.id}`}
            showsVerticalScrollIndicator={false}
            style={{ height: listHeight }}
            containerStyle={Platform.OS === 'android' || Platform.OS === 'ios' ? styles.containerMobile : styles.container}
            renderItem={({ item, index, drag, isActive }) => <View>
                {(item.id !== 'blank') ?
                    dragToSort ?
                        <TouchableOpacity style={{ display: 'flex', flexDirection: 'row', marginBottom: 12, width: "100%", justifyContent: 'space-between' }}
                            onLongPress={() => {
                                Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light)
                                drag()
                            }}>
                            <View style={styles.category}>
                                <Text style={styles.categoryName}>{item.name}</Text>
                            </View>
                            <View style={{ width: 40, height: 50, justifyContent: 'center', alignItems: "center", alignSelf: 'flex-end', }}>
                                <BarsIcon />
                            </View>
                        </TouchableOpacity>
                        :
                        <View style={{ display: 'flex', flexDirection: 'row', marginBottom: 12, width: "100%", justifyContent: 'space-between' }}>
                            <TouchableOpacity
                                onLongPress={() => {
                                    Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium)
                                    props.navigation.navigate("EditContext", { contextId: item.id })
                                }}>
                                <View style={styles.category}>
                                    <Text style={styles.categoryName}>{item.name}</Text>
                                </View>
                            </TouchableOpacity>
                            <Pressable
                                onPress={() => {
                                    showAlert(item)
                                }}>
                                <View style={{ width: 40, height: 50, justifyContent: 'center', alignItems: "center", alignSelf: 'flex-end', }}>
                                    <RedTrashCanIcon />
                                </View>
                            </Pressable>
                        </View>
                    : <BlankItem />}
            </View>
            } />
        <BottomButton singleTap={addContextOnPress} text={"Add Context"} delay={0} />
        <Dialog
            open={openDeleteDialog}
            onOpenChange={(open) => {
                if (!open) {
                    setToDeleteContext(undefined)
                }
            }}

        >
            <DialogContent>
                <View style={dialogStyles.Dialog} >
                    <Button
                        onPress={() => {
                            if (toDeleteContext) {
                                deleteContext(toDeleteContext, { deleteTasks: true })
                                    .then(() => {
                                        setOpenDeleteDialog(false)
                                    }).catch(() => {
                                        setOpenDeleteDialog(false)
                                    })
                            }
                        }}
                        title='Delete related tasks'
                        color={cColors.CONTEXT_MENU_BG_COLOR} />
                    <Button
                        onPress={() => {
                            if (toDeleteContext) {
                                deleteContext(toDeleteContext, { deleteTasks: false })
                                    .then(() => {
                                        setOpenDeleteDialog(false)
                                    }).catch(() => {
                                        setOpenDeleteDialog(false)
                                    })
                            }
                        }}
                        title='Keep tasks'
                        color={cColors.CONTEXT_MENU_BG_COLOR} />
                    <Button onPress={() => {
                        setToDeleteContext(undefined)
                        setOpenDeleteDialog(false)
                    }}
                        title='Cancel'
                        color={cColors.DELETE_BUTTON_BG_COLOR} />
                </View>
            </DialogContent>
        </Dialog>

    </View>
}


const mapStateToProps = function (state: any) {
    return {
        contexts: state.contexts
    }
}

export default connect(mapStateToProps)(ContextView)
