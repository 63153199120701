import Svg, { Path, Rect, ClipPath, Defs, Line, G } from "react-native-svg";
interface IconProps {
    width?: number, height?: number, color?: string
}
export const SaveForLaterIcon = ({ width = 26, height = 26, color = "#3C4352" }: IconProps) => <Svg width={width} height={height} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <G clipPath="url(#clip0_501_24)">
        <G clipPath="url(#clip1_501_24)">
            <Line x1="-1" y1="9" x2="27" y2="9" stroke={color} strokeWidth="2" strokeLinecap="round" />
            <Line x1="-1" y1="15" x2="27" y2="15" stroke={color} strokeWidth="2" strokeLinecap="round" />
            <Path d="M-1.03223 21H15.4194" stroke={color} strokeWidth="2" stroke-linecap="round" />
            <Line x1="-1" y1="3" x2="27" y2="3" stroke={color} strokeWidth="2" strokeLinecap="round" />
            <Path d="M22.1935 18V24M25.0967 21H19.2903" stroke={color} strokeWidth="1.5" stroke-linecap="round" strokeLinejoin="round" />
        </G>
    </G>
    <Defs>
        <ClipPath id="clip0_501_24">
            <Rect width="26" height="26" fill="white" />
        </ClipPath>
        <ClipPath id="clip1_501_24">
            <Rect width="30" height="26" fill="white" transform="translate(-2)" />
        </ClipPath>
    </Defs>
</Svg>

export const FocusContextIcon = ({ width = 26, height = 26, color = "#3C4352" }: IconProps) => <Svg xmlns="http://www.w3.org/2000/svg" fill="none" width={width} height={height} viewBox="0 0 24 24" strokeWidth={1.5} stroke={color}>
    <Path strokeLinecap="round" strokeLinejoin="round" d="M2.25 7.125C2.25 6.504 2.754 6 3.375 6h6c.621 0 1.125.504 1.125 1.125v3.75c0 .621-.504 1.125-1.125 1.125h-6a1.125 1.125 0 01-1.125-1.125v-3.75zM14.25 8.625c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v8.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-8.25zM3.75 16.125c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v2.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-2.25z" />
</Svg>

export const RemindLaterIcon = ({ width = 26, height = 26, color = "#3C4352" }: IconProps) => (<Svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.0} width={width} height={height} stroke={color}>
    <Path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
</Svg>)