import { Context, ContextOrder, MappedContexts } from "../../types/Context"
import { ContextActions } from "./contextsActions"

const contexts: Context[] = [
    { name: 'Personal', pattern: 'Me' },
    { name: 'Minilist', pattern: 'M' },
    { name: 'Sphere', pattern: 'S' },
    { name: 'Event Builder', pattern: 'EB' },
].map((c, index: number) => ({ ...c, id: `${index}` } as Context))

let contextsById: MappedContexts = {}

// for (let c of contexts) {
//     contextsById[c.id] = c
// }

export function contextReducer(state = contextsById, action: { type: string, payload?: any }): MappedContexts {
    switch (action.type) {
        case ContextActions.START_UPDATE_CONTEXT_ORDER_ACTION:
            const contexts = { ...state }
            const keys = Object.keys(action.payload as ContextOrder)
            for (let key of keys) {
                contexts[key].position = (action.payload as ContextOrder)[key]
            }
            return contexts
        case ContextActions.SUCCESS_LOAD_CONTEXTS_ACTION:
            return { ...action.payload }
        case ContextActions.SUCCESS_SAVE_CONTEXT_ACTION:
            return { ...state, [action.payload.id]: action.payload }
        default:
            return state
    }
}
