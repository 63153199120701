import { Platform, StyleSheet } from "react-native"
import { HOST } from "../api";
import Task from '../types/Task';
import TaskInformationView from "../components/TaskInformationView";
import React, { useRef } from "react";
import { loadAllTasks } from "../services/tasksService";
import { store } from "../redux";
import ModalContainer from "../components/ModalContainer";
import CustomContainer from "../components/CustomContainer";
import { connect } from "react-redux";
import { fetchConfig } from "../utils/headers";

const EditTaskScreenWrapper = (props: { navigation?: any, route: any, allTasks: any, user: any }) => {

    const { allTasks, user: { token } } = props

    const { taskId } = props.route.params

    const infoViewRef = useRef<any>();

    const saveTask = (task: Task, userId: string) => fetch(`${HOST}/tasks/${task.id}`, {
        method: "PUT",
        body: JSON.stringify(task),
        ...fetchConfig()

    })
        .then(res => res.json())
        .catch(e => {

        })

    const task = allTasks['Daily'][taskId] || allTasks['SaveForLater'][taskId] || allTasks['JustToday'][taskId] || undefined;
    // console.log("view.taskType", allTasks['SaveForLater'][taskId].type)
    // console.log("view.taskType", allTasks['JustToday'][taskId].type)
    return <ModalContainer
        navigation={props.navigation}
        enableRightButton={true}
        rightButtonText={"Save"}
        rightButtonWidth={Platform.OS === 'ios' ? 55 : 80}
        rightButtonOnClick={() => {
            if (infoViewRef && infoViewRef.current) {
                infoViewRef.current.saveTask()
            }
        }}
    >
        <CustomContainer>
            <TaskInformationView
                ref={infoViewRef}
                navigation={props.navigation}
                mode="edit"
                task={task}
                saveTask={(_task) => saveTask(_task, token)}
                afterButtonPress={(task) => {
                    if (props.navigation) {
                        props.navigation.goBack()
                    }
                    loadAllTasks()
                }}
            />
        </CustomContainer>
    </ModalContainer>
}

const mapStateToProps = function (state: any) {
    return {
        allTasks: state.allTasks,
        user: state.user
    }
}

export default connect(mapStateToProps)(EditTaskScreenWrapper)
