import * as React from 'react';
import { Pressable, View, Text, StyleSheet, Animated, Platform } from "react-native"
import Task, { TaskType } from '../types/Task';
import BouncyCheckbox from 'react-native-bouncy-checkbox';
import Swipeable from 'react-native-gesture-handler/Swipeable'
import * as Haptics from 'expo-haptics';
import BlankItem from './BlankItem';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { checkTaskUpdate, deleteTask, moveTaskToSaveForLaterAndReload, loadAllTasks } from '../services/tasksService';
import { warningToast } from '../utils/toast';
import { Path, Svg } from 'react-native-svg';
import { openMenuWithTaskId } from '../redux/visual/visualActions';
import { store } from '../redux';
import cColors from '../styles/componentColors'
const styles = StyleSheet.create({
    item: {
        backgroundColor: cColors.TASK_ITEM_BG_COLOR,
        paddingTop: 14 + 4,
        paddingBottom: 14 + 4,
        paddingLeft: 18 + 4 + 4,
        paddingRight: 18 + 4,
        flexDirection: 'row',
        alignItems: 'center',
        borderColor: cColors.TASK_ITEM_BORDER_COLOR,
        borderRadius: 12,
        flex: 1,
        display: 'flex'
    },
    itemWithColor: {
        backgroundColor: cColors.TASK_ITEM_BG_COLOR,
        paddingTop: 14 + 4,
        paddingBottom: 14 + 4,
        paddingLeft: 14,
        flexDirection: 'row',
        alignItems: 'center', 
        borderColor: cColors.TASK_ITEM_BORDER_COLOR,
        borderRadius: 12,
        flex: 1,
        display: 'flex'
    },
    itemFocused: {
        backgroundColor: cColors.TASK_ITEM_BG_COLOR,
        paddingTop: 14,
        paddingBottom: 14,
        paddingHorizontal: 0,
        paddingLeft: 18 + 4,
        paddingRight: 18 + 4,
        flexDirection: 'row',
        alignItems: 'center',
        borderWidth: 4,
        borderColor: cColors.TASK_ITEM_BORDER_COLOR,
        borderTopRightRadius: 12,
        borderBottomRightRadius: 12,
        flex: 1,
        display: 'flex',
        borderLeftWidth: 4,
        borderTopLeftRadius: 12,
        borderBottomLeftRadius: 12,
    },
    itemFocusedWithColor: {
        backgroundColor: cColors.TASK_ITEM_BG_COLOR,
        paddingTop: 14,
        paddingBottom: 14,
        paddingHorizontal: 0,
        paddingLeft: 14,
        flexDirection: 'row',
        alignItems: 'center',
        borderWidth: 4,
        borderLeftWidth: 0,
        borderColor: cColors.TASK_ITEM_BORDER_COLOR,
        borderTopRightRadius: 12,
        borderBottomRightRadius: 12,
        flex: 1,
        display: 'flex',
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0
    },
    saveForLaterItem: {
        display: 'flex',
        backgroundColor: cColors.TASK_ITEM_BG_COLOR,
        paddingTop: 14 + 4,
        paddingBottom: 14 + 4,
        flexDirection: 'row',
        alignItems: 'center',
        borderColor: 'white',
        borderRadius: 12,
        borderLeftWidth: 0,
        flex: 1,
        borderTopLeftRadius: 12,
        borderBottomLeftRadius: 12
    },
    saveForLaterItemWithColor: {
        display: 'flex',
        backgroundColor: cColors.TASK_ITEM_BG_COLOR,
        paddingTop: 14 + 4,
        paddingBottom: 14 + 4,
        flexDirection: 'row',
        alignItems: 'center',
        borderColor: 'white',
        borderRadius: 12,
        borderLeftWidth: 0,
        flex: 1,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
    },
    saveForLaterItemFocused: {
        backgroundColor: cColors.TASK_ITEM_BG_COLOR,
        paddingTop: 14,
        paddingBottom: 14,
        flexDirection: 'row',
        alignItems: 'center',
        borderWidth: 4,
        borderColor: cColors.TASK_SFL_ITEM_FOCUS_BORDER_COLOR,
        borderRadius: 12,
        flex: 1,
        display: 'flex',
        borderLeftWidth: 4,
        borderTopLeftRadius: 12,
        borderBottomLeftRadius: 12
    },
    saveForLaterItemFocusedWithColor: {
        backgroundColor: cColors.TASK_ITEM_BG_COLOR,
        paddingTop: 14,
        paddingBottom: 14,
        flexDirection: 'row',
        alignItems: 'center',
        borderWidth: 4,
        borderColor: cColors.TASK_SFL_ITEM_FOCUS_BORDER_COLOR,
        borderRadius: 12,
        flex: 1,
        display: 'flex',
        borderLeftWidth: 0,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
    },
    dailyItem: {
        backgroundColor: cColors.TASK_DAILY_ITEM_BG_COLOR,
        paddingTop: 14 + 4,
        paddingBottom: 14 + 4,
        paddingLeft: 18 + 4,
        flexDirection: 'row',
        alignItems: 'center',
        borderColor: cColors.TASK_DAILY_ITEM_BORDER_COLOR,
        borderRadius: 12,
        flex: 1,
        display: 'flex'
    },
    dailyItemWithColor: {
        backgroundColor: cColors.TASK_DAILY_ITEM_BG_COLOR,
        paddingTop: 14 + 4,
        paddingBottom: 14 + 4,
        paddingLeft: 14,
        flexDirection: 'row',
        alignItems: 'center',
        borderColor: cColors.TASK_DAILY_ITEM_BORDER_COLOR,
        borderRadius: 12,
        flex: 1,
        display: 'flex'
    },
    dailyItemFocused: {
        backgroundColor: cColors.TASK_DAILY_ITEM_BG_COLOR,
        paddingTop: 14,
        paddingBottom: 14,
        paddingLeft: 18,
        flexDirection: 'row',
        alignItems: 'center',
        borderWidth: 4,
        borderColor: cColors.TASK_DAILY_ITEM_BORDER_COLOR,
        borderRadius: 12,
        flex: 1,
        borderTopLeftRadius: 12,
        borderBottomLeftRadius: 12,
        display: 'flex'
    },
    dailyItemFocusedWithColor: {
        backgroundColor: cColors.TASK_DAILY_ITEM_BG_COLOR,
        paddingTop: 14,
        paddingBottom: 14,
        paddingLeft: 14,
        flexDirection: 'row',
        alignItems: 'center',
        borderWidth: 4,
        borderColor: cColors.TASK_DAILY_ITEM_BORDER_COLOR,
        borderRadius: 12,
        flex: 1,
        borderLeftWidth: 0,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        display: 'flex'
    },
    itemText: {
        fontSize: 20,
        // backgroundColor: 'green',
        color: cColors.TASK_ITEM_TEXT_COLOR,
        flexWrap: 'wrap',
        display: 'flex',
        // paddingTop: os.type === 'Linux' ? 5 : 0
    },
    itemTextChecked: {
        display: 'flex',
        fontSize: 20,
        // backgroundColor: 'red',
        color: cColors.TASK_ITEM_CHECKED_TEXT,
        flexWrap: 'wrap',
        // paddingTop: os.type === 'Linux' ? 5 : 0
    }
});
const ClonableIcon = () => <Svg fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" height={20} width={20}>
    <Path stroke-linecap="round" stroke-linejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
</Svg>

const SFLIconColor = cColors.SFL_ICON_COLOR

// const SFIcon2 = () => <Svg width={36} height={36} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
//     <Path fill-rule="evenodd" clip-rule="evenodd" d="M12 9C11.2044 9 10.4413 9.31607 9.87868 9.87868C9.31607 10.4413 9 11.2044 9 12V36C9 36.7956 9.31607 37.5587 9.87868 38.1213C10.4413 38.6839 11.2044 39 12 39H36C36.7956 39 37.5587 38.6839 38.1213 38.1213C38.6839 37.5587 39 36.7956 39 36V12C39 11.2044 38.6839 10.4413 38.1213 9.87868C37.5587 9.31607 36.7956 9 36 9H12ZM7.75736 7.75736C8.88258 6.63214 10.4087 6 12 6H36C37.5913 6 39.1174 6.63214 40.2426 7.75736C41.3679 8.88258 42 10.4087 42 12V36C42 37.5913 41.3679 39.1174 40.2426 40.2426C39.1174 41.3679 37.5913 42 36 42H12C10.4087 42 8.88258 41.3679 7.75736 40.2426C6.63214 39.1174 6 37.5913 6 36V12C6 10.4087 6.63214 8.88258 7.75736 7.75736Z" fill={SFLIconColor} />
//     <Path fill-rule="evenodd" clip-rule="evenodd" d="M40 20.5H7V17.5H40V20.5Z" fill={SFLIconColor} />
//     <Path fill-rule="evenodd" clip-rule="evenodd" d="M40 31.5H7V28.5H40V31.5Z" fill={SFLIconColor} />
//     <Path fill-rule="evenodd" clip-rule="evenodd" d="M23 40V30H25V40H23Z" fill={SFLIconColor} />
//     <Path d="M29 34.5C29 35.3284 28.3284 36 27.5 36C26.6716 36 26 35.3284 26 34.5C26 33.6716 26.6716 33 27.5 33C28.3284 33 29 33.6716 29 34.5Z" fill={SFLIconColor} />
//     <Path d="M22 34.5C22 35.3284 21.3284 36 20.5 36C19.6716 36 19 35.3284 19 34.5C19 33.6716 19.6716 33 20.5 33C21.3284 33 22 33.6716 22 34.5Z" fill={SFLIconColor} />
// </Svg>

// @ts-ignore
const SFIcon3 = () => <Svg width={36} height={36} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <Path stroke={SFLIconColor} stroke-linecap="round" stroke-linejoin="round" d="M16.5 3.75V16.5L12 14.25 7.5 16.5V3.75m9 0H18A2.25 2.25 0 0120.25 6v12A2.25 2.25 0 0118 20.25H6A2.25 2.25 0 013.75 18V6A2.25 2.25 0 016 3.75h1.5m9 0h-9" />
</Svg>



const RenderRight = (
    taskId: string,
    deleteTask: (taskId: string) => void,
    saveTask: (taskId: string) => void,
    hideChangeTaskButton: boolean,
    itemHeight?: number
) => (progress: Animated.AnimatedInterpolation<number>, dragX: Animated.AnimatedInterpolation<number>) => {
    return (<>
        <TouchableOpacity onPress={() => deleteTask(taskId)}>
            <View style={{ width: 80, backgroundColor: cColors.DELETE_BUTTON_BG_COLOR, alignItems: "center", justifyContent: 'center', marginTop: 10, 
            // paddingVertical: hideChangeTaskButton ? (14 + 4) : (14 * 2), 
            height: itemHeight }}>
                <Text style={{
                    color: cColors.DELETE_BUTTON_TEXT_COLOR,
                    fontSize: 20
                }}>Delete</Text>
            </View>
        </TouchableOpacity>
        {!hideChangeTaskButton && <TouchableOpacity onPress={() => saveTask(taskId)}>
            <View style={{
                width: 80,
                backgroundColor: cColors.SFL_BUTTON_BG_COLOR,
                alignItems: "center",
                justifyContent: 'center',
                marginTop: 10,
                // paddingVertical: hideChangeTaskButton ? (14 + 4) : (14 + 8),
                height: itemHeight
            }}>
                <View>
                    <SFIcon3 />
                </View>
            </View>
        </TouchableOpacity>}
    </>
    )
}


const getStyles = (isFocused: boolean, taskType: TaskType, listType: string, contextColor?: string) => {
    return isFocused
        ? (taskType === 'DAILY_ROUTINE'
            ? !contextColor ? styles.dailyItemFocused : styles.dailyItemFocusedWithColor
            : (taskType === 'SAVE_FOR_LATER' || listType === 'JustYesterdayIncomplete')
                ? !contextColor ? styles.saveForLaterItemFocused : styles.saveForLaterItemFocusedWithColor
                : !contextColor ? styles.itemFocused : styles.itemFocusedWithColor)
        : (taskType === 'DAILY_ROUTINE'
            ? !contextColor ? styles.dailyItem : styles.dailyItemWithColor
            : taskType === 'SAVE_FOR_LATER'
                ? !contextColor ? styles.saveForLaterItem : styles.saveForLaterItemWithColor
                : !contextColor ? styles.item : styles.itemWithColor)
}

const PADDING_DEFAULT = 14;
function getTextContainerPaddingLeft(isFocused: boolean, taskType: TaskType, listType: string, contextColor?: string): number {
    return isFocused
        ? (taskType === 'DAILY_ROUTINE'
            ? !contextColor ? PADDING_DEFAULT : PADDING_DEFAULT
            : (taskType === 'SAVE_FOR_LATER' || listType === 'JustYesterdayIncomplete')
                ? !contextColor ? PADDING_DEFAULT - 4 + 8 : PADDING_DEFAULT
                : !contextColor ? PADDING_DEFAULT : PADDING_DEFAULT)
        : (taskType === 'DAILY_ROUTINE'
            ? !contextColor ? PADDING_DEFAULT : PADDING_DEFAULT
            : taskType === 'SAVE_FOR_LATER'
                ? !contextColor ? PADDING_DEFAULT + 8 : PADDING_DEFAULT
                : !contextColor ? PADDING_DEFAULT : PADDING_DEFAULT)
}

function getTextContainerPaddingRight(isFocused: boolean, taskType: TaskType, listType: string, contextColor?: string): number {
    return isFocused
        ? (taskType === 'DAILY_ROUTINE'
            ? !contextColor ? PADDING_DEFAULT : PADDING_DEFAULT
            : (taskType === 'SAVE_FOR_LATER' || listType === 'JustYesterdayIncomplete')
                ? !contextColor ? PADDING_DEFAULT : PADDING_DEFAULT
                : !contextColor ? PADDING_DEFAULT - 4 : PADDING_DEFAULT)
        : (taskType === 'DAILY_ROUTINE'
            ? !contextColor ? PADDING_DEFAULT : PADDING_DEFAULT
            : taskType === 'SAVE_FOR_LATER'
                ? !contextColor ? PADDING_DEFAULT : PADDING_DEFAULT + 4
                : !contextColor ? PADDING_DEFAULT : PADDING_DEFAULT)
}

const TaskItemView = (props: { id: string, task: Task, isFocused: boolean, setAsFocused: (index: string) => void, userId: string, reloadAllTasks: () => void, navigation: any, hideCheckbox?: boolean, listType: 'Daily' | 'JustToday' | 'SaveForLater' | 'JustYesterdayIncomplete', contextColor?: string, isSearchResult?: boolean }) => {
    const os = window["OsEnv"] ?? { type: "", platform: "" } as { type: string, platform: string }
    const platform = window.navigator.platform ?? "";
    const [itemHeight, setItemHeight] = React.useState<number | undefined>()
    const itemRef = React.useRef() as React.MutableRefObject<any>;

    const moveToSaveForLater = (taskId: string) => {
        moveTaskToSaveForLaterAndReload(taskId, true)
    }

    const onPress = () => {
        if (props.isFocused && !props.hideCheckbox) {
            props.setAsFocused("")
        } else {
            props.setAsFocused(props.task.id)
        }
    }

    const checkOnPress = () => {
        checkTaskUpdate(!props.task.checked, props.task.id, props.task.checked_id)
            .then(() => {
                loadAllTasks(true)
            }).catch(e => {
                warningToast("failed to save", 'bottom')
            })
    }

    const onLongPress = () => {
        Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium)
        props.navigation.navigate('EditTask', { taskId: props.task.id })
    }
    const deleteTaskById = (taskId: string) => {
        deleteTask(taskId, { reloadSaveForLater: props.task.type === 'SAVE_FOR_LATER', removeTaskFromSearchResults: Boolean(props.isSearchResult) })
    }

    React.useEffect(() => {
        function onContextMenu(ev: any) {
            ev.preventDefault()
            store.dispatch(openMenuWithTaskId(props.task.id))
            return false
        }
        if (Platform.OS === 'web') {
            if (itemRef.current) {
                (itemRef.current as HTMLElement).addEventListener('contextmenu', onContextMenu, false);
            }
        }
        return () => {
            if (Platform.OS === 'web') {
                if (itemRef.current) {
                    itemRef.current.removeEventListener('contextmenu', onContextMenu)
                }
            }
        }
    }, [itemRef, props])

    const textStyle = (props.task.checked ? styles.itemTextChecked : styles.itemText)
    const adjustedTextStyle = { ...textStyle, paddingTop: os.type === 'Linux' || platform.includes("Linux") ? 2.25 : 0, marginTop: Platform.OS === 'android' ? -4 : 0 }

    const Content = () => <View style={{ marginTop: 10, borderRadius: 9999 }}>
        <Pressable
            onPress={onPress}
            onLongPress={onLongPress}
        >
            <View ref={itemRef} style={{ width: '100%', flexDirection: 'row', display: 'flex', flex: 1 }}
                onLayout={({ nativeEvent }) => {
                    setItemHeight(nativeEvent.layout.height)
                }}
            >
                {props.contextColor && <View style={{ width: 10, backgroundColor: props.contextColor ? props.contextColor : undefined, borderBottomLeftRadius: 12, borderTopLeftRadius: 12 }}></View>}
                <DivOrFrag className={"TaskItem"}>
                    <View style={getStyles(props.isFocused, props.task.type, props.listType, props.contextColor)}>
                        {!props.hideCheckbox && <BouncyCheckbox
                            fillColor={cColors.TASK_ITEM_CHECKBOX_FILL_COLOR}
                            size={40}
                            iconStyle={{
                                borderRadius: 14,
                            }}
                            style={{ height: 40, width: 40 }}
                            onPress={checkOnPress}
                            disableBuiltInState
                            isChecked={props.task.checked}
                        />}
                        <View style={{
                            paddingLeft: getTextContainerPaddingLeft(props.isFocused, props.task.type, props.listType, props.contextColor),
                            paddingRight: getTextContainerPaddingRight(props.isFocused, props.task.type, props.listType, props.contextColor),
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            flex: 1,
                        }}>
                            <Text style={adjustedTextStyle}>{props.task.title}</Text>
                        </View>
                    </View>
                </DivOrFrag>
                {props.task.is_clonable && <View style={{ position: 'absolute', right: 18, top: 18 }}><ClonableIcon /></View>}
            </View>
        </Pressable>
    </View>

    return (
        props.id !== 'blank' ?
            Platform.OS === "web"
                ?
                <Content />
                :
                <Swipeable
                    useNativeAnimations
                    overshootLeft={false}
                    overshootRight={false}
                    renderRightActions={RenderRight(props.task.id, deleteTaskById, moveToSaveForLater, !!props.hideCheckbox, itemHeight)}
                >
                    <Content />
                </Swipeable >
            :
            <BlankItem />
    )
}

const DivOrFrag = (props: { children?: any, style?: any, className?: string }) => {
    return Platform.OS === 'web' ? <div style={{ flex: 1 }} className={props.className}>{props.children}</div> : <>{props.children}</>
}
export default TaskItemView
