import { Platform } from "react-native"
import { store } from "../redux"


export function fetchLoginConfig() {
    const isDevWeb = Platform.OS === 'web' && window.location.host === 'localhost:19006'
    const headerStarter: any = {
        "Content-Type": "application/json",
    }

    const config = {}

    if (Platform.OS === "ios") {
        headerStarter["Client"] = "ios"
    } else if (Platform.OS === 'android') {
        headerStarter["Client"] = "android"
        // @ts-ignore
    } else if (Platform.OS === 'web' && window.__TAURI__) {
        headerStarter["Client"] = "desktop"
        // @ts-ignore
    } else if (Platform.OS === 'web' && isDevWeb) {
        headerStarter["Client"] = "dev-web"
    }
    else {
        // @ts-ignore
        config['credentials'] = 'include'
    }
    // @ts-ignore
    config['headers'] = headerStarter;

    return config

}


export function fetchConfig(authToken?: string | null) {
    const { user } = store.getState()
    const token = !!authToken ? authToken : user.token

    const headerStarter: any = {
        "Content-Type": "application/json",
    }

    const config = {}

    if (!!token) {
        headerStarter["Authorization"] = token
    } else {
        // @ts-ignore
        config['credentials'] = 'include'
    }

    // @ts-ignore
    config['headers'] = headerStarter;
    return config
}

export function composeHeader(authToken?: string | null) {
    // console.log("composeHeader()")

}