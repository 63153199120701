import { HOST } from "../api";
import { store } from "../redux";
import {
  startDeleteContextAction,
  startLoadContextsAction,
  startSaveContextAction,
  startUpdateContextOrderAction,
  successDeleteContextAction,
  successLoadContextsAction,
  successSaveContextAction,
  successUpdateContextOrderAction,
} from "../redux/contexts/contextsActions";
import { normalizeContexts } from "../utils/context";
import { Context, ContextOrder } from "../types/Context";
import { errorToast } from "../utils/toast";
import { openDatabase, simpleTransaction } from "./localDBService";
import { WebSQLDatabase } from "expo-sqlite";
import { db, loadAllTasks } from "./tasksService";
import { fetchConfig } from "../utils/headers";

export async function loadAllContexts() {
  try {
    const { token, mode } = store.getState().user;
    store.dispatch(startLoadContextsAction());
    let contexts = [];
    if (mode === "local") {
      // tasks = await loadJustTodayTasksLocal(parseCurrentDateTimeIso())
    } else {
      contexts = await fetch(`${HOST}/contexts`, {
        method: 'GET',
        ...fetchConfig()
      }).then((res) => res.json());
    }

    store.dispatch(successLoadContextsAction(normalizeContexts(contexts)));
  } catch (e) {
    errorToast(e);
  }
}

export async function saveContext(
  newContext: Context
): Promise<Context | null> {
  try {
    // console.log(newContext)
    const { token, mode } = store.getState().user;
    store.dispatch(startSaveContextAction());
    let context = null;
    if (mode === "local") {
      // tasks = await loadJustTodayTasksLocal(parseCurrentDateTimeIso())
    } else {
      context = await fetch(`${HOST}/contexts`, {
        method: "POST",
        body: JSON.stringify({
          ...newContext,
        } as Context),
        ...fetchConfig()


      }).then((res) => res.json());
    }

    store.dispatch(successSaveContextAction(context));
    return context;
  } catch (e) {
    errorToast(e);
    return null;
  }
}
export async function setContextPublic(
  contextId: string
): Promise<Context | null> {
  try {
    const { token, mode } = store.getState().user;
    let resp = await fetch(`${HOST}/actions/set-context-as-public`, {
      method: "POST",
      body: JSON.stringify({
        context_id: contextId,
      }),
      ...fetchConfig()
    }).then((res) => res.json());
    return resp;
  } catch (e) {
    errorToast(e);
    return null;
  }
}
export async function setContextPrivate(
  contextId: string
): Promise<Context | null> {
  try {
    const { token, mode } = store.getState().user;
    let resp = await fetch(`${HOST}/actions/set-context-as-private`, {
      method: "POST",
      body: JSON.stringify({
        context_id: contextId,
      }),
      ...fetchConfig()


    }).then((res) => res.json());

    return resp
  } catch (e) {
    errorToast(e);
    return null;
  }
}
export async function updateContext(
  newContext: Context
): Promise<Context | null> {
  try {
    // console.log(newContext)
    const { token, mode } = store.getState().user;
    store.dispatch(startSaveContextAction());
    let context = null;
    if (mode === "local") {
      // tasks = await loadJustTodayTasksLocal(parseCurrentDateTimeIso())
    } else {
      context = await fetch(`${HOST}/contexts/${newContext.id}`, {
        method: "PUT",
        body: JSON.stringify({
          ...newContext,
        } as Context),
        ...fetchConfig()

      }).then((res) => res.json());
    }

    store.dispatch(successSaveContextAction(context));
    return context;
  } catch (e) {
    errorToast(e);
    return null;
  }
}

export async function updateContextOrder(
  contextOrder: ContextOrder
): Promise<ContextOrder | null> {
  try {
    // console.log(newContext)
    const { token, mode } = store.getState().user;
    store.dispatch(startUpdateContextOrderAction(contextOrder));
    let contextOrderResp = null;
    if (mode === "local") {
      // tasks = await loadJustTodayTasksLocal(parseCurrentDateTimeIso())
    } else {
      contextOrderResp = await fetch(`${HOST}/actions/update-context-order`, {
        method: "POST",
        body: JSON.stringify(contextOrder),
        ...fetchConfig()

      }).then((res) => res.json());
    }

    store.dispatch(successUpdateContextOrderAction(contextOrder));
    return contextOrder;
  } catch (e) {
    errorToast(e);
    return null;
  }
}

export const deleteContext = async (
  contextId: string,
  options: { deleteTasks?: boolean }
) => {
  try {
    const { token, mode } = store.getState().user;

    store.dispatch(startDeleteContextAction(contextId));

    if (mode === "local") {
      await simpleTransaction(db, "DELETE FROM contexts WHERE id = ?", [
        contextId,
      ]);
    } else {
      const params = options.deleteTasks ? `?delete_tasks=${true}` : "";
      await fetch(`${HOST}/contexts/${contextId}${params}`, {
        method: "DELETE",
        ...fetchConfig()
        
      }).then((res) => res.json());
    }

    await loadAllTasks(false, true);
    await loadAllContexts();

    store.dispatch(successDeleteContextAction(contextId));
  } catch (e) { }
};
