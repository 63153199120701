import { Text, StyleSheet, TouchableWithoutFeedback, Platform, View } from "react-native"
import cColors from "../styles/componentColors"
import React, { useState, useRef, forwardRef, useImperativeHandle, useEffect } from "react"
import { SafeAreaView } from 'react-native-safe-area-context';
import BottomButton from "../components/BottomButton";
import Task, { TaskType } from '../types/Task';
import { parseCurrentDateTimeIso } from "../utils/date";
import { warningToast } from '../utils/toast';
import { RootSiblingParent } from 'react-native-root-siblings';
import ContextBubblesTopList from "./ContextBubblesTopList";
import { loadAllContexts } from "../services/contextService";
import TypeSelect, { SelectOption } from "./TypeSelect";
import TextInput from "./TextInput";
import { ScrollView } from "react-native-gesture-handler";


type MappedTypeOptions = { [key: string]: SelectOption }

const typeOptions: MappedTypeOptions = {
    "JUST_TODAY": { label: "Just Today", value: "JUST_TODAY" },
    "SAVE_FOR_LATER": { label: "Save For Later (SFL)", value: "SAVE_FOR_LATER" },
    "SAVE_FOR_LATER_WITH_CLONABLE": { label: "SFL and clonable", value: "SAVE_FOR_LATER_WITH_CLONABLE" },
    // "SAVE_FOR_LATER_REMIND_TOMORROW": { label: "SFV and remind tomorrow", value: "SAVE_FOR_LATER_REMIND_TOMORROW" },
    "DAILY_ROUTINE": { label: "Daily", value: "DAILY_ROUTINE" },
}

const options: SelectOption[] = [typeOptions.JUST_TODAY, typeOptions.SAVE_FOR_LATER, typeOptions.SAVE_FOR_LATER_WITH_CLONABLE,
// typeOptions.SAVE_FOR_LATER_REMIND_TOMORROW, 
typeOptions.DAILY_ROUTINE]

const TaskInformationView = (props: { navigation: any, mode: 'edit' | 'create' | 'multi-create', afterButtonPress: (task: Task) => void, task?: Task, index?: number, saveTask: (task: Task) => Promise<Task>, withContext?: string, withType?: TaskType }, ref?: any) => {
    const inputEl = useRef(null);
    const [title, setTitle] = useState<string>((props.task && props.task.title) ? props.task.title : '');
    const [details, setDetails] = useState((props.task && props.task.details) ? props.task.details : '');
    const [contextIndex, setContextIndex] = useState<string | null>((props.withContext) ? props.withContext : (props.task && props.task.context_id) ? props.task.context_id : null);
    const [selectedTaskTypeOption, setSelectedTaskTypeOption] = useState<string>(props.withType ? props.withType : (props.task && props.task.type) ? props.task.is_clonable ? "SAVE_FOR_LATER_WITH_CLONABLE" : props.task.type : 'JUST_TODAY');
    // console.log(props.task?.is_clonable)
    const [selectedTaskType, setSelectedTaskType] = useState<TaskType>(props.withType ? props.withType : (props.task && props.task.type) ? props.task.type : 'JUST_TODAY');
    const [isClonable, setIsClonable] = useState(props.task ? props.task.is_clonable : false)

    const onButtonPress = async () => {
        try {
            const date = selectedTaskType === "JUST_TODAY" ? parseCurrentDateTimeIso() : ''

            if (props.mode === "create" || props.mode === 'multi-create') {
                let payload: Task = {
                    "id": "",
                    "title": title,
                    "type": selectedTaskType,
                    "details": details,
                    "date": date,
                    "checked": false,
                    "checked_id": "",
                    "active": true,
                    "created_by": "",
                    "created_at": 0,
                    "updated_at": 0,
                    "is_clonable": isClonable
                }
                if (title !== "") {
                    payload['context_id'] = contextIndex ? contextIndex : ""
                    const task = await props.saveTask(payload)
                    props.afterButtonPress(task)
                } else {
                    warningToast("Title cannot be blank", 'center')
                }


            } else if (props.mode === 'edit' && props.task) {
                let payload: Task = props.task
                payload.title = title
                payload.details = details
                payload.type = selectedTaskType
                payload.date = selectedTaskType === 'DAILY_ROUTINE' ? "" : date
                payload.is_clonable = isClonable
                payload['context_id'] = contextIndex || ""

                if (title !== "") {
                    const task = await props.saveTask(payload)
                    props.afterButtonPress(task)
                } else {
                    warningToast("Title cannot be blank", 'center')
                }
            }
        } catch (e) {
            // console.log(e)
        }
    }

    useImperativeHandle(ref, () => ({
        setNewTitle(title: string) {
            setTitle(title)
        },
        createNewTask() {
            const date = selectedTaskType === "JUST_TODAY" ? parseCurrentDateTimeIso() : ''

            const payload: Task = {
                "id": "",
                "title": title,
                "type": selectedTaskType,
                "details": details,
                "date": date,
                "checked": false,
                "checked_id": "",
                "active": true,
                "created_by": "swag",
                "created_at": 0,
                "updated_at": 0,
                "is_clonable": isClonable
            };

            payload['context_id'] = contextIndex ? contextIndex : ""

            props.saveTask(payload)
        },
        saveTask() {
            onButtonPress()
        }
    }));

    const labels = {
        title: {
            'edit': "Edit task",
            'create': 'Create a new task',
            'multi-create': "Create a new task"
        },
        button: {
            'edit': "Save Changes",
            'create': 'Create Task',
            'multi-create': "Create All Tasks"
        }
    }

    useEffect(() => {
        // loadAllContexts()

        // const focusInputWeb = () => {
        //     // @ts-ignore
        //     inputEl.current.focus()
        // }

        // if (Platform.OS === 'web' && props.mode !== 'edit' && !once.current) {
        //     once.current = true
        //     // console.log("focus")
        //     focusInputWeb()
        // }

        const shortcutHandler = (event: any) => {
            if ((event.code === 'Enter' && event.metaKey) || (event.ctrlKey && event.code === 'Enter')) {
                onButtonPress()
            }
        }

        // @ts-ignore
        if (window.__TAURI__) {
            document.body.addEventListener("keypress", shortcutHandler)
        }
        return () => {
            // @ts-ignore
            if (window.__TAURI__) {
                document.body.removeEventListener('keypress', shortcutHandler)
            }
        }
    }, [onButtonPress, title, details, contextIndex])

    return (<RootSiblingParent>
        <SafeAreaView style={styles.container} edges={['left']}>
            <View style={styles.headerContainer}>
                <Text style={styles.header}>{labels.title[props.mode]}</Text>
            </View>
            <View style={{
                paddingHorizontal: 14
            }}>
                <TextInput
                    style={{
                        color: 'white',
                        fontSize: 26,
                        paddingVertical: 2,
                        marginTop: 0,
                        marginBottom: 12,
                        border: 'none',
                        paddingLeft: 0

                    }}
                    placeholderTextColor={cColors.INPUT_PLACEHOLDER_TEXT_COLOR}
                    placeholder="Title of task"
                    onChangeText={text => setTitle(text)}
                    defaultValue={title}
                    autoFocus={props.mode === 'create'}
                    numberOfLines={1}
                />

                <View
                    style={{
                        height: Platform.OS === 'web' ?  4 * 28: 3 * 28,
                        marginBottom: 20
                    }}>
                    <TextInput
                        style={{
                            color: 'white',
                            fontSize: 22,
                            flex: 1,
                            border: 'none',
                            paddingLeft: 0,
                            paddingRight: 0,
                        }}
                        placeholderTextColor={cColors.INPUT_PLACEHOLDER_TEXT_COLOR}
                        placeholder="Write task details here "
                        onChangeText={details_str => { setDetails(details_str) }}
                        defaultValue={details}
                        numberOfLines={3}
                        multiline
                        editable
                    />
                </View>
            </View>
            <ContextBubblesTopList
                marginSide="left"
                contextIndex={contextIndex}
                setContextIndex={(index: string | null) => {
                    setContextIndex(index)
                }}
                navigation={props.navigation}
                hideBuiltInFilters
            />
            <Text style={styles.label}>{"Select Type"}</Text>
            <View
                style={{ paddingHorizontal: 0 }}
            >
                {/* @ts-ignore */}
                <TypeSelect
                    options={options}
                    //@ts-ignore
                    onValueChange={(taskTypeValue) => {
                        if (taskTypeValue.value === 'SAVE_FOR_LATER_WITH_CLONABLE') {
                            setSelectedTaskType("SAVE_FOR_LATER")
                            setSelectedTaskTypeOption("SAVE_FOR_LATER_WITH_CLONABLE")
                            setIsClonable(true)
                        } else if (taskTypeValue.value === 'SAVE_FOR_LATER_REMIND_TOMORROW') {
                            setSelectedTaskType("SAVE_FOR_LATER")
                            setSelectedTaskTypeOption("SAVE_FOR_LATER_REMIND_TOMORROW")
                            setIsClonable(false)
                        }
                        else {
                            setSelectedTaskType(taskTypeValue.value as TaskType)
                            setSelectedTaskTypeOption(taskTypeValue.value)
                            setIsClonable(false)
                        }
                    }}
                    selected={typeOptions[selectedTaskTypeOption]}
                />
            </View>
            <BottomButton singleTap={onButtonPress} text={labels.button[props.mode]} delay={0} />
        </SafeAreaView >
    </RootSiblingParent>)
}

const styles = StyleSheet.create({
    header: {
        paddingTop: 0,
        paddingBottom: 10,
        fontSize: 18,
        color: cColors.HEADER_TEXT_COLOR
    },
    container: {
        backgroundColor: cColors.PAGE_BG_COLOR,
        flex: 1,
        paddingHorizontal: 0
    },
    text: {
        color: 'white'
    },
    label: {
        fontSize: 18,
        marginBottom: Platform.OS === 'ios' ? -16 : 10,
        color: cColors.LABEL_TEXT_COLOR,
        paddingHorizontal: 12
    },
    headerContainer: {
        paddingVertical: 6,
        paddingHorizontal: 12
    }
})

export default forwardRef(TaskInformationView)