import { State } from "react-native-gesture-handler";
import { combineReducers } from "redux";
import { MappedTasks } from "../../types/Task";
import { TasksActions } from "./tasksActions";
import { seperateAndKeepOrderTasks, seperateAndSortTasks, seperateTasks } from "../../utils/tasks";
import { UserActions } from "../user/userActions";

const DAILY_TASKS_INITIAL_STATE = {}

const dailyTasksReducer = (dailyTasks: MappedTasks = DAILY_TASKS_INITIAL_STATE, action: { type: string, payload?: any }) => {
    switch (action.type) {
        case TasksActions.START_UPDATE_CHECK_STATE_ACTION:
            if (dailyTasks[action.payload.taskId]) {
                const cp = { ...dailyTasks }
                cp[action.payload.taskId].checked = action.payload.checkState
                return cp
            } else {
                return dailyTasks
            }
        case TasksActions.SUCCESS_LOAD_DAILY_TASKS_ACTION:
            if (action.payload.disallowSort) {
                return seperateAndKeepOrderTasks(dailyTasks, action.payload.tasks)
            }
            // seperate into each section
            return seperateAndSortTasks(action.payload.tasks)
        case UserActions.SUCCESSFUL_LOGOUT_ACTION:
            return {}
        default:
            return dailyTasks
    }
}

const justTodayTasksReducer = (justTodayTasks: MappedTasks = DAILY_TASKS_INITIAL_STATE, action: { type: string, payload?: any }) => {
    switch (action.type) {
        case TasksActions.SUCCESS_SAVE_TASK_ACTION:
            if (action.payload.task && action.payload.task.type === 'JUST_TODAY') {
                const jTTasks = { ...justTodayTasks }
                jTTasks[action.payload.task.id] = action.payload.task
                return jTTasks
            }
            return { ...justTodayTasks }
        case TasksActions.START_UPDATE_CHECK_STATE_ACTION:
            if (justTodayTasks[action.payload.taskId]) {
                const cp = { ...justTodayTasks }
                cp[action.payload.taskId].checked = action.payload.checkState
                return cp
            } else {
                return justTodayTasks
            }
        case TasksActions.SUCCESS_LOAD_JUST_TODAY_TASKS_ACTION:
            if (action.payload.disallowSort) {
                return seperateAndKeepOrderTasks(justTodayTasks, action.payload.tasks)
            }
            // seperate into each section
            return seperateAndSortTasks(action.payload.tasks)
        case UserActions.SUCCESSFUL_LOGOUT_ACTION:
            return {}
        default:
            return justTodayTasks
    }
}

const justYesterdayIncompleteTasksReducer = (justTodayTasks: MappedTasks = DAILY_TASKS_INITIAL_STATE, action: { type: string, payload?: any }) => {
    switch (action.type) {
        case TasksActions.START_UPDATE_CHECK_STATE_ACTION:
            if (justTodayTasks[action.payload.taskId]) {
                const cp = { ...justTodayTasks }
                cp[action.payload.taskId].checked = action.payload.checkState
                return cp
            } else {
                return justTodayTasks
            }
        case TasksActions.SUCCESS_LOAD_JUST_YESTERDAY_TASKS_ACTION:
            if (action.payload.disallowSort) {
                return seperateAndKeepOrderTasks(justTodayTasks, action.payload.tasks)
            }
            // seperate into each section
            return seperateAndSortTasks(action.payload.tasks)
        case UserActions.SUCCESSFUL_LOGOUT_ACTION:
            return {}
        default:
            return justTodayTasks
    }
}

const saveForLaterReducer = (saveForLaterTasks: MappedTasks = DAILY_TASKS_INITIAL_STATE as MappedTasks, action: { type: string, payload?: any }) => {
    switch (action.type) {
        case TasksActions.SUCCESS_LOAD_SAVE_FOR_LATER_TASKS_ACTION:
            return action.payload.tasks as MappedTasks
        case UserActions.SUCCESSFUL_LOGOUT_ACTION:
            return { ...DAILY_TASKS_INITIAL_STATE }
        case TasksActions.START_MOVE_TASKS_TO_JUST_TODAY_ACTION:
            const saveForLaterTasksCopy = { ...saveForLaterTasks }
            for (let taskid of action.payload.taskIds) {
                delete saveForLaterTasksCopy[taskid as string]
            }
            return saveForLaterTasksCopy
        default:
            return saveForLaterTasks as MappedTasks
    }
}

const resultSearchReducer = (resultTasks: MappedTasks = DAILY_TASKS_INITIAL_STATE as MappedTasks, action: { type: string, payload?: any }) => {
    switch (action.type) {
        case TasksActions.SUCCESS_LOAD_SEARCH_RESULT_TASKS_ACTION:
            return action.payload.tasks as MappedTasks
        case UserActions.SUCCESSFUL_LOGOUT_ACTION:
            return { ...DAILY_TASKS_INITIAL_STATE }
        case TasksActions.RESET_SEARCH_RESULT_TASKS_ACTION:
            return { ...DAILY_TASKS_INITIAL_STATE }
        case TasksActions.DELETE_TASK_FROM_SEARCH_RESULTS_ACTION:
            const newResultsTasks = { ...resultTasks }
            delete newResultsTasks[action.payload as string]
            return newResultsTasks
        default:
            return resultTasks as MappedTasks
    }
}


export const allTasksReducer = combineReducers({
    'Daily': dailyTasksReducer,
    'SaveForLater': saveForLaterReducer,
    'JustToday': justTodayTasksReducer,
    'JustYesterdayIncomplete': justYesterdayIncompleteTasksReducer,

    'SearchResults': resultSearchReducer
})
